import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './services/auth-guard.service';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./pages/menu/menu.module').then((m) => m.MenuPageModule),
     // pathMatch:'full',
    // canActivate:[AuthGuardService]
  },
  // {
  //   path: 'Dashboard',
  //   loadChildren: () => import('./elintnewlayout/elint-dashboard/elint-dashboard.module').then( m => m.ElintDashboardPageModule)
  // },
  // {
  //   path: 'login',
  //   loadChildren: () =>
  //     import('./login/login.module').then((m) => m.LoginPageModule),
  // },
  // {
  //   path: 'all-alarms',
  //   loadChildren: () =>
  //     import('./all-alarms/all-alarms.module').then(
  //       (m) => m.AllAlarmsPageModule
  //     ),
  // },

  // {
  //   path: '',
  //   redirectTo: 'login',
  //   pathMatch: 'full'
  // },
  // {
  //   path: 'login',
  //   loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  // },
  // {
  //   path: 'menu',
  //   loadChildren: () => import('./pages/menu/menu.module').then( m => m.MenuPageModule)
  // }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      useHash: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
