import { Injectable } from '@angular/core';
import { WebsocketInstance } from '../interface/websocketinstance';
import { webSocket,WebSocketSubject } from 'rxjs/webSocket';
@Injectable({
  providedIn: 'root',
})
export class WebSocketConnectionService {
  connectionEstablish$:WebSocketSubject<any>;
  webSocketConnectionEstablish = false;
  constructor(private webConnection: WebsocketInstance) {}

  setResponceConnection = (setClass: WebsocketInstance) => {
    this.webConnection = setClass;
  };

  connectLiveSocket = (url, input) => {
    this.connectionEstablish$ = webSocket(url);
    this.connectionEstablish$.next(input);
    // console.log('websocket connected!');
    this.connectionEstablish$.subscribe(
      (res) => {
        this.webSocketConnectionEstablish = true;
        this.webConnection.webSocketResponce(res);
      },
      (err) => {
        this.webConnection.webSocketErrorReturn(err);
      },
      () => {
        console.log('websocket closed!!');
      }
    );
  };

  reRequestOnSameConnection = (input) => {
    this.connectionEstablish$.next(input);
  };

  closeConnection = () => {
  
  
    if(this.webSocketConnectionEstablish){
      this.webSocketConnectionEstablish = false;
       this.connectionEstablish$.unsubscribe();
       console.log('socketclosed');
       
    }
   
  };
}
