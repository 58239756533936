import {
  Directive,
  HostBinding,
  OnInit,
  HostListener,
  OnDestroy,
} from '@angular/core';
import { CommonService } from '../services/common.service';
@Directive({
  selector: '[appLoder]',
})
export class LoderDirective implements OnInit, OnDestroy {
  timeouts = [];
  constructor(private commonserves: CommonService) {}
  ngOnInit(): void {}
  @HostListener('click')
  onMouseOver() {
    this.commonserves.presentLoader();
    this.timeouts.push(
      setTimeout(() => {
        this.commonserves.dismissLoader();
      }, 2000)
    );
    // console.log('clikeddddddddddddddddddddddddddddddddddd');
  }
  ngOnDestroy(): void {
    this.timeouts.forEach((sets) => clearTimeout(sets));
  }
}
