import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { serverUrl } from 'src/environments/environment';
import { DashboardRequestDto } from '../dto/dashboard-request-dto';
import { TicketRequestDto } from '../dto/ticket-request-dto';
import { CommonService } from './common.service';
import { HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root',
})
export class AjaxService {
  webURL = serverUrl.webUrl;
  adminURL = serverUrl.adminUrl;
  httpNormalString = {
    headers: new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    }),
    responseType: 'text' as 'json',
  };

  constructor(
    private httpClient: HttpClient,
    private commonService: CommonService
  ) {}

  handleError = async (error: HttpErrorResponse) => {
    if (this.commonService.isLoading == true) {
      this.commonService.dismissLoader();
    }
    // console.log('Orginal Error' + JSON.stringify(error));
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    return null;
  };

  private extractData(res: Response) {
    const body = res;
    return body || [];
  }

  private extractObject(res: Response) {
    const body = res;
    return body.hasOwnProperty('length') ? body : [];
  }

  loginAuthenticationService(loginDTO: any): Observable<Object> {
    this.onUrlChange(serverUrl);
    return this.httpClient.post(`${this.webURL}/login/authenticate`, loginDTO);
  }

  getDashboardDatas(dashboardDTO: DashboardRequestDto): Observable<Object> {
    return this.httpClient.post(
      `${this.webURL}/dashboard/dashboarddatas`,
      dashboardDTO
    );
  }

  getPreferencesData(httpParams: HttpParams,trace=0): Observable<Object> {
    let res={params: httpParams}
if(trace){
  res['responseType']= 'text';
}; //console.log('from prefers',res);

    return this.httpClient.get(`${this.webURL}/login/getpreferenesData`, {
      ...res
    });
  }
  //reports/escalationMatrixReport
  getTickets(ticketRequestDto: TicketRequestDto) {
    return this.httpClient
      .post(`${this.webURL}/reports/getTickets`, ticketRequestDto)
      .pipe(map(this.extractData), catchError(this.handleError));
  }

  getRunHours(siteInfo: object) {
    return this.httpClient.post(
      `${this.webURL}/site/dg/dgandMobiledg/runsHours`,
      siteInfo
    );
  }
  getRunHoursFuelKwh(siteInfo: object) {
    return this.httpClient.post(
      `${this.webURL}/site/dg/runshoursFuelKwh`,
      siteInfo
    );
  }
  getRemarks(compid: string) {
    return this.httpClient.get(
      `${this.webURL}/global/recipients/list?companyId=${compid}`
    );
  }
  
  addRemark(type,item){
    return this.httpClient.post(
      `${this.webURL}/global/recipients/${type}/add`,item,{
        responseType: 'text'
      }
    );
  }
  deleteRemarks(type,item){
    return this.httpClient.post(
      `${this.webURL}/global/recipients/${type}/delete`,item,{
        responseType: 'text'
      }
    );
  }
  getLiveChartData(siteInfo: object) {
    // console.trace('tracing servicessssssssssafadafdsdd');
    return this.httpClient.post(
      `${this.webURL}/dashboard/setLiveChartDataForElint`,
      siteInfo
    );
  }

  getCummulativeKwh(siteInfo: object) {
    return this.httpClient
      .post(`${this.webURL}/reports/getCumulativeKwhReport`, siteInfo)
      .pipe(map(this.extractObject), catchError(this.handleError));
  }

  getCummulativeKwhReport(siteInfo: object) {
    return this.httpClient
      .post(`${this.webURL}/reports/getCumulativeKwhReport`, siteInfo)
      .pipe(map(this.extractObject), catchError(this.handleError));
  }

  getEscalationMatrixReport(siteInfo: object) {
    return this.httpClient.post(
      `${this.webURL}/reports/escalationMatrixReport`,
      siteInfo
    );
  }
  getForgotPassword(httpParams: HttpParams) {
    return this.httpClient.get(`${this.webURL}/login/forgotpassword`, {
      params: httpParams,
    });
  }
  getGridData(httpParams: HttpParams, url: string) {
    return this.httpClient.get(`${this.webURL}${url}`, { params: httpParams });
  }
  ajaxPostString(url, data: object) {
    return this.httpClient.post(`${this.webURL}${url}`, data, {
      responseType: 'text',
    });
  }
  ajaxPostStringwithParams(url, data: object, httpParams: HttpParams) {
    return this.httpClient.post(`${this.webURL}${url}`, data, {
      responseType: 'text',
      params: httpParams,
    });
  }
  ajaxPostFileParams(url, data: FormData, httpParams: HttpParams) {
    let file = data.get('file');
    //console.log(data, data.get('file'), file.valueOf());

    return this.httpClient.post(`${this.webURL}${url}`, data, {
      responseType: 'text',
      params: httpParams, 
    });
  }
  ajaxAlarmConfig(url, vin){
     return this.httpClient.post(this.adminURL + url, vin);
  }
    
  ajaxGetString(url, httpParams: HttpParams,isweburl='') {
    let weburl=isweburl ||this.webURL
    return this.httpClient.get(`${weburl}${url}`, {
      params: httpParams,
      ...this.httpNormalString,
    });
  }
  ajaxPost(url, requestBody: object) {
    return this.httpClient.post(this.webURL + url, requestBody);
  }
  ajaxAdminGet(url,params){
    return this.httpClient.get(this.adminURL + url, {
      params: params,
    });
  }
  ajaxAdminPost(url, requestBody: object) {
    return this.httpClient.post(this.adminURL + url, requestBody);
  }
  ajaxAdminPostWithparams(pars: object, url: string){
    let params = new HttpParams();
    Object.entries(pars).forEach((x: string[]) => {
      params = params.set(x[0], x[1]);
    });
    return this.httpClient.post(`${this.adminURL}${url}`, {
      params: params
    });
  }
  ajaxCallWithparams(pars: object, url: string) {
    // let a = { key11: '1', key22: '2' };
    let params = new HttpParams();
    Object.entries(pars).forEach((x: string[]) => {
      params = params.set(x[0], x[1]);
    });
    return this.httpClient.get(`${this.webURL}${url}`, {
      params: params,
      ...this.httpNormalString,
    });
  }
  onUrlChange(serverUrl){
    this.webURL = serverUrl.webUrl;
    this.adminURL = serverUrl.adminUrl;
  }
}
