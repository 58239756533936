import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HttpClientModule } from '@angular/common/http';
import { SharedmoduleModule } from './sharedmodule/sharedmodule.module';
// import { jqxGaugeComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxgauge';
import {jqxGaugeComponent} from 'jqwidgets-ng/jqxgauge';
import {jqxGaugeModule} from 'jqwidgets-ng/jqxgauge';
// import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { Device } from '@awesome-cordova-plugins/device/ngx';
// import { NavBarComponent } from './nav-bar/nav-bar.component';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { LoderDirective } from './directives/loder.directive'
import { FilterPipe } from './pipes/filter.pipe';
// class ScreenOrientationMock extends ScreenOrientation {
//   lock(type) {
//     return new Promise((resolve, reject) => {
//       resolve("locked");
//     })
//   }
// }

@NgModule({
  declarations: [AppComponent, LoderDirective 
    // jqxGaugeComponent
  ],
  entryComponents: [],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule, HttpClientModule,
    // SharedmoduleModule
    // jqxGaugeComponent
    // jqxGaugeModule
    
  ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }, 
    ScreenOrientation, Device, StatusBar,
    // { provide: ScreenOrientation, useClass: ScreenOrientationMock }
  ],
  bootstrap: [AppComponent],
  exports: [
    // SharedmoduleModule
    // jqxGaugeComponent
    // jqxGaugeModule
  ]
})
export class AppModule {}
